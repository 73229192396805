<template>
  <div class="main-box">
    <el-form class="form-inline" :inline="true" size="small">
      <el-form-item label="">
        <el-input v-model="search.keywords" placeholder="请输入关键词" size="small" clearable></el-input>
      </el-form-item>
      <el-form-item label="">
        <el-select v-model="search.status" placeholder="请选择状态" clearable>
          <el-option :key="1" label="待打款" :value="1"></el-option>
          <el-option :key="2" label="打款中" :value="2"></el-option>
          <el-option :key="3" label="已打款" :value="3"></el-option>
          <el-option :key="4" label="已拒绝" :value="4"></el-option>
          <el-option :key="5" label="已取消" :value="5"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="">
        <el-date-picker
            size="small"
            v-model="dateRange"
            type="daterange"
            value-format="yyyy-MM-dd"
            range-separator="至"
            start-placeholder="提交开始日期"
            end-placeholder="提交结束日期">
        </el-date-picker>
      </el-form-item>
      <el-form-item label="">
        <el-button type="primary" size="small" @click="handleSearch">搜索</el-button>
      </el-form-item>
    </el-form>
    <el-table
        :data="tableData"
        ref="rfTable"
        style="width: 100%"
        size="mini"
        @selection-change="handleSelectionChange">
      <el-table-column
          prop="name"
          label="提现用户"
          align="left"
          min-width="250">
        <template slot-scope="scope">
          <div v-if="scope.row.name" class="member-box">
            <img :src="scope.row.avatar" />
            <div>
              <div>{{scope.row.name}}（{{scope.row.user_id}}）</div>
              <div>{{scope.row.mobile}}</div>
            </div>
          </div>
          <div v-else>-</div>
        </template>
      </el-table-column>
      <el-table-column
          prop="order_sn"
          label="提现单号"
          align="center"
          width="180">
      </el-table-column>
      <el-table-column
          prop="amount"
          label="提现金额"
          align="center"
          width="180">
        <template slot-scope="scope">
          ￥{{scope.row.amount}}
          <div>含手续费：{{scope.row.fee}}</div>
        </template>
      </el-table-column>
      <el-table-column
          prop="withdraw_channel"
          label="提现渠道"
          align="center"
          width="100">
        <template slot-scope="scope">
          <span v-if="scope.row.withdraw_channel==1">银行卡</span>
          <span v-if="scope.row.withdraw_channel==2">微信</span>
          <span v-if="scope.row.withdraw_channel==3">支付宝</span>
        </template>
      </el-table-column>
      <el-table-column
          prop="withdraw_channel"
          label="提现账户"
          align="left"
          width="190">
        <template slot-scope="scope">
          <div v-for="(item,index) in scope.row.withdraw_account" :key="index">{{index}}：{{item}}</div>
        </template>
      </el-table-column>
      <el-table-column
          prop="status"
          label="状态"
          align="center"
          width="120">
        <template slot-scope="scope">
          <span v-if="scope.row.status==0">待打款</span>
          <span v-if="scope.row.status==1">打款中</span>
          <span v-if="scope.row.status==2">已打款</span>
          <span class="red" v-if="scope.row.status==3">已拒绝：{{scope.row.remark}}</span>
          <span v-if="scope.row.status==4">已取消</span>
        </template>
      </el-table-column>
      <el-table-column
          prop="shenhe_time"
          label="处理时间"
          align="center"
          width="150">
      </el-table-column>
      <el-table-column
          prop="operate_name"
          label="操作人"
          align="center"
          width="150">
      </el-table-column>
      <el-table-column
          fixed="right"
          prop="ftime"
          label="提交时间"
          align="center"
          width="90">
      </el-table-column>
      <el-table-column
          fixed="right"
          label="操作"
          align="center"
          width="100">
        <template slot-scope="scope">
          <div v-if="scope.row.status==0">
            <el-button type="text" size="small" @click="agree(scope.row)">同意</el-button>
            <el-button type="text" size="small" @click="refuse(scope.row)">拒绝</el-button>
          </div>
          <div v-else>-</div>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
        background
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
        layout="total, sizes, prev, pager, next, jumper"
        :page-sizes="[10, 20, 30, 40]"
        :total="search.total">
    </el-pagination>
  </div>
</template>
<script>

export default {
  components: {},
  data() {
    return {
      info: false,
      source_domain: this.config.SOURCE_DOMAIN,
      search: {
        current_page: 1,
        total: 0,
        limit: 10,
        keywords: '',
        status: ''
      },
      tableData: [],
      dateRange: ['','']
    };
  },
  created() {
    this.getList();
  },
  mounted() {
  },
  computed: {},
  methods: {
    agree(item) {
      console.log(item)
      var title = '确认同意打款吗?'
      if(item.withdraw_channel!=2) {
        title = '确认线下打款吗？'
      }
      this.$confirm(title, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$api.order.financeWithdrawOperate({id:item.id,status:1,remark:''},res=>{
          if(res.errcode==0) {
            this.success(res.errmsg)
            this.getList()
          } else {
            this.fail(res.errmsg)
          }
        })
      }).catch(() => {
      });
    },
    refuse(item) {
      this.$prompt('请输入拒绝原因', '', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputPattern: /[\d\D]+/,
        inputErrorMessage: '请输入拒绝原因',
        inputPlaceholder: '请输入拒绝原因',
      }).then(({ value }) => {
        if(!value) {
          this.fail('请输入拒绝原因')
          return false
        }
        this.$api.order.financeWithdrawOperate({id:item.id,status:2,remark:value},res=>{
          if(res.errcode==0) {
            this.success(res.errmsg)
            this.getList()
          } else {
            this.fail(res.errmsg)
          }
        })
      }).catch(() => {
      });
    },
    getList() {
      var that = this
      that.showLoading()
      var param = {
        page: that.search.current_page,
        page_size: that.search.limit,
        keywords: that.search.keywords,
        status: that.search.status,
      }
      if(this.dateRange) {
        param.start_time = this.dateRange[0]
        param.end_time = this.dateRange[1]
      }
      this.$api.order.financeWithdrawIndex(param, function (res) {
        that.hideLoading()
        if (res.errcode == 0) {
          that.tableData = res.data.data
          that.search.current_page = res.data.current_page
          that.search.total = res.data.total
        } else {
          that.fail(res.errmsg)
        }
      })
    },
    handleSearch() {
      this.search.current_page = 1
      this.getList()
    },
    handleSelectionChange(rows) {
    },
    handleCurrentChange(val) {
      this.search.current_page = val
      this.getList()
    },
    handleSizeChange(val) {
      this.search.current_page = 1
      this.search.limit = val
      this.getList()
    }
  }
};
</script>
<style scoped>
</style>
